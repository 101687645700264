import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="hero">
      <img height={250} src={require("../images/me.png")}></img>
      <div className="right">
        <div className="hero-text">
          <span className="text">Hey I'm</span>
          <span className="text-name"> Shivam.</span>
        </div>
        <div className="subtitle">Full Stack Developer</div>
        <div style={{ fontSize: "1.4rem" }} className="subtitle">
          <b>Javascript Enthusiast</b> with experience in Java, Python, and
          Android
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
